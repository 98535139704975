import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"


import imageGrid from "../../images/casestudy/grid-jcb2-min.png"

import { useEffect, useRef } from "react"
import gsap from "gsap"



const OttaPage = ({ location }) => {

  const introRef = useRef();
  const skillsRef = useRef();
  const h1Ref = useRef();
  //const screenRef = useRef();

    // wait until DOM has been rendered
    useEffect(() => {
      gsap.from(h1Ref.current, { y: "+=30", opacity:0, ease: "power4.out", duration:1, delay:.5 });
      gsap.from(introRef.current, { y: "+=30", opacity:0,  ease: "power4.out", duration:1, delay:.7 });
      gsap.from(skillsRef.current, {  opacity:0, ease: "power4.out", duration:1, delay:1  });
     // gsap.from(screenRef.current, {  x: "+=30", ease: "power4.out", duration:1, delay:0  });
  
    });


  return (
    <Layout>
      <Seo title="Otta 01" index={false} follow={false} />
 

      <div className="page-body">

        <div className="page">
          <div id="casestudy" className="casestudy jcb">
            <section className="header jcb-header">

              <div className="inner">


                <div className="section_wrap-main">
                  <div className="section_wrap-1 text--invert">
                    JCB
                    <h1 ref={h1Ref} className="text--shadow">LiveLink Portal</h1>
                    <p ref={introRef} className="text--shadow">A large scale redesign project to create a flexible user centered fleet management framework.
                    </p>
                    <p>&nbsp;</p>
                    <small ref={skillsRef}> UX | UI | Interaction</small>
                  </div>
                  <div className="section-wrap-2">
                    <div  className="section_screen-wrap-2">
                      {/* <img src={laptopImage} alt="" className="hero_img-1"></img> */}

                      <StaticImage
                      src="../../images/casestudy/ipad-jcb-llp2-min.jpg"
                      width={760}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Dashboard tablet view"
                      className="hero_img-1"
                      
                    />
                      


                    </div>
                    <div className="hero_phone-wrap-main">
                      <div className="hero-image-2">
                        {/* <img src={phoneImage} alt="" className="hero_img-2">

                          </img> */}
                          <StaticImage
                      src="../../images/casestudy/iphone-jcb-llp-min.jpg"
                      width={300}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Dashboard phone view"
                      className="hero_img-2"
                      
                    />

                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </section>
            <section className="section-1">
              <div className="inner">

                <div className="eyebrow title">Unified platform</div>
                <h2>A grown-up fleet management tool</h2>
                <div className="row">
                  <div className="fifty">
                    <p>Fleet management is big business, knowing where your fleet equipment is, when servicing is due and being able to identify telematics issues promptly are paramout to a successful hire or construction businsess.</p>

                  </div>
                  <div className="fifty">
                    <p>The result was a mobile first and fully responsive web application, focussed on owner operators but with the ability to scale to fleet managers widescreen TVs.</p>
                  </div>
                </div>


              </div>
              <div class="graphic-grid-wrapper">
                <div class="graphic-grid">
                  {/* <img src={imageGrid} alt="Grid of devices and example screens"/> */}

                  <StaticImage
                      src="../../images/casestudy/grid-jcb2-min.png"
                      width={1690}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Grid of devices and example screens"
                      backgroundColor="#fff"
                      
                    />

                </div>
              </div>
              <div className="angle-divider">

                <svg className="divider divider-bottom divider--dark" viewBox="0 0 1366 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1366 0V90V105H0V90L1366 0Z" fill="" /></svg>

              </div>

            </section>
            <section className="section-2 section--dark">
              <div className="inner">
                <div className="row">
                  <div className="fifty">

                    <h2>The Brief</h2>
                    <p>The initial brief was to ‘add a layer of polish’ an internally produced web application that had been in development for 18 months. The client wanted to align the product in quality to an application my agency had recently delivered.</p>

                  </div>
                  <div className="fifty">
                  <StaticImage
                      src="../../images/casestudy/original-layout-min.jpg"
                      width={660}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Original supplied design"
                      className="image-effect"
                      
                    />
                    {/* <img className="image-fill image-effect" src={originalDesign} alt="Original supplied design" /> */}
                    
                    <p className="small-print ">The original design &#8212; A flat design, confusing navigation and lack of user first thinking created a web app that was difficult to understand, navigate or trust.</p>
                  </div>
                </div>
              </div>
            </section>
            <section className="section-3 section--light">
              <div className="angle-divider">

                <svg className="divider divider--flip divider--dark" viewBox="0 0 1366 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1366 0V90V105H0V90L1366 0Z" fill="" /></svg>

              </div>
              <div className="inner">
                <div className="center">

                  <h2 className="p-top">Pitch</h2>
                  <p>After solving the initial request we pitched the proposition that a better solution required more investment. The client was receptive and we began 6 week UX project to clarify the users needs and validate the current offering.</p>
                </div>
                <div className="image-grid-container">
                  <div className="image-content">
                    {/* <img className="image-effect" src={planningPostits} alt="Planning the application" /> */}
                    <StaticImage
                      src="../../images/casestudy/planning-postits-min.jpg"
                      width={484}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Whiteboarding the application"
                      className="image-effect"
                      
                    />
                    {/* <h3 className="title">title</h3> */}
                    <p className="small-print ">Using a whiteboard and post-its to understand the primary features and section of the site.</p>
                  </div>

                  <div className="image-content">
                  <StaticImage
                      src="../../images/casestudy/sitemap-min.jpg"
                      width={484}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Application sitemap"
                      className="image-effect"
                      
                    />
                    {/* <img className="image-effect" src={sitemap} alt="Application site map" /> */}
                    {/* <h3 className="title">title</h3> */}
                    <p className="small-print "> By updating the site map and presenting it back to the client we were able to clarify the focus of the site.</p>
                  </div>

                </div>
              </div>
            </section>
            <section className="section-4 user-research section--fixed-light">
              <div className="angle-divider">

                <svg className="divider divider--flip divider--white" viewBox="0 0 1366 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1366 0V90V105H0V90L1366 0Z" fill="" /></svg>

              </div>
              <div className="inner">
                <div className="third third--last">
                  <div className="text--shadow-lift p-top">
                  <h2>User research</h2>
                  <p>Expert reviews, interviews and in the field customer visits followed. Two primary personas were identified and their needs and contexts began to drive the fundamental shift in layout and positioning. </p>
                  </div>
                  
                </div>
              </div>
            </section>
            <section className="section-5 section--light">
              <div className="angle-divider">

                <svg className="divider divider--white divider--overlap" viewBox="0 0 1366 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1366 0V90V105H0V90L1366 0Z" fill="" /></svg>

              </div>
              <div className="inner">
                <div className="row">
                <div className="third third--first">
                  <h2>Lean UX</h2>
                  <p>Working with the internal team more closely enabled us to perform more regular mockup, design iterations and testing. From joining the daily stand-ups and sprint planning sessions to extended exploration of the users. UX become embedded into the process for a better outcome.</p>
                </div>
                <div className="twothird">
                  <div className="image-twothird-wrapper" >
                 {/* <img className="auto-width image-effect" src={userTesting} alt="Early user testing in Lookback" /> */}
                 <StaticImage
                      src="../../images/casestudy/twothird-lookback-min.jpg"
                      width={880}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Application sitemap"
                      className="image-effect"
                      
                    />

                 


                 </div>
                </div>
                </div>
              </div>
            </section>
            <section className="outcomes section--fixed-light">
              <div className="inner">
                <div className="center">

                  <h2>Outcomes</h2>
                  <p>To enable greater speed of production a Design System was created to enable common atomic design elements to be shared. Hand off to developers via Zeplin and reuse of common components via Sketch and DSM is also increased the pace of development and testing.</p>
                </div>

                {/* <div className="row row-narrow">
                  <div className="thirty-three">
                    <div className="outcome-card">
                      <div className="large-number">56</div>
                      <p>info here</p>
                    </div>
                  </div>
                  <div className="thirty-three ">
                    <div className="outcome-card">
                      <div className="large-number">19</div>

                      <p>info here</p>
                    </div>
                  </div>
                  <div className="thirty-three">
                    <div className="outcome-card">
                      <div className="large-number">32</div>

                      <p>info here</p>
                    </div>
                  </div>
                </div> */}

              </div>
              <div className="image-assets">

                {/* <img src={footerImage} alt="Design system components" /> */}

                <StaticImage
                      src="../../images/casestudy/footer-jcb-min.png"
                      width={1200}
                      quality={95}
                      formats={["AUTO"]}
                      alt="Design system components"
                      
                    />

               


              </div>
            </section>

          </div>
        </div>

      </div>

      {/* <div className="work-wrapper">

        <div className="pagination-block">
          <div className="previous">Previous</div>

          <div className="next">Next</div>
        </div>

      </div> */}

    </Layout>
  );

};



export default OttaPage;
